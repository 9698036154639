import axios from "axios";
import { env } from "../utils/env";
import { SplashScreenList } from "../types/SplashScreenList";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";

interface SplashScreenResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: SplashScreenList[];
}

const fetch = (authHeader: string) => () =>
  axios.get<SplashScreenResponse>(
    `${env.verifyServiceUrl}/individual/splash_screen/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useSplashScreen = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["splashScreenList"], fetch(authHeader), {
    enabled: true,
    retry: false,
    select: (response) => response.data.results[0], // Select the results array
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
      if(err.request.status === 401) {
        navigate(ROUTE.LOGOUT);
      }
    }
  });
};
