import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { DocumentCaptureType } from "../types/AdditionalDocumentsType";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetch = (authHeader: string) => () =>
  axios.get<DocumentCaptureType[]>(
    `${env.verifyServiceUrl}common/document_capture_type_list/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useDocumentCaptureTypeList = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();

  return useQuery(["document_capture_type_list"], fetch(authHeader), {
    retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
