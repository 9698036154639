import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { IndividualCheckResponse } from "../types/Individual";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetch = (authHeader: string, individualCheckId: number) => () =>
  axios.get<IndividualCheckResponse>(
    `${env.verifyServiceUrl}individual/check/${individualCheckId}/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useIndividualCheck = (id: number) => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["individualCheckDetails", id], fetch(authHeader, id), {
    retry: false,
    enabled: !!id,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
