import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { PagedResponse } from "../types/Common";
import { User } from "../types/User";
import { env } from "../utils/env";
import { ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";


const fetchUsers = (authHeader: string) => () =>
  axios.get<PagedResponse<User[]>>(`${env.verifyServiceUrl}user/user/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useUsers = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  const query = useQuery(["users"], fetchUsers(authHeader), {retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });

  return query;
};
