import { useNavigate, useParams } from "react-router-dom";
import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { ApplicationTypeNewFrame } from "./ApplicationTypeNewFrame";
import { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { useMandateType } from "../../data/useMandateType";
import SettingsApplicationTypeForms from "./SettingsApplicationTypeForms";

const SettingsApplicationTypeNewForms = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedMandateTypeId, setSelectedMandateTypeId] = useState<
    number | null
  >(null);

  const { data: mandateTypeQuery, isLoading } = useMandateType(
    selectedMandateTypeId
  );

  const formsData = mandateTypeQuery?.data?._form;

  useEffect(() => {
    if (params.id) {
      const id = parseInt(params.id);
      setSelectedMandateTypeId(id);
    }
  }, [params.id]);

  const handleContinue = () => {
    navigate(`/settings/application-types/${selectedMandateTypeId}/documents`);
  };

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" minHeight="25vh">
        <Loader />
      </Flex>
    );
  }

  return (
    <ApplicationTypeNewFrame step="forms">
      <Box pl={4} pr={2} pt={4} flex={4} minWidth={400}>
        <H2 fontSize="3">Create New Application Type</H2>

        <SettingsApplicationTypeForms
          initialFormsData={formsData}
          mandateTypeId={selectedMandateTypeId}
        />

        <Flex my={4} justifyContent="end">
          <Button type="button" onClick={handleContinue} size="regular">
            Continue
          </Button>
        </Flex>
      </Box>
    </ApplicationTypeNewFrame>
  );
};

export default SettingsApplicationTypeNewForms;
