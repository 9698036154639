import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";
import { displayError } from "../utils/error-handling";
import { useEffect, useState, useRef } from "react";
import { useAuth } from "../context/AuthContext";

const AxiosInterceptor = ({ children }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState("");
  const in401Error = useRef(false);

  const setError = (val) => {
    if (val !== message) {
      setMessage(val);
    }
  };

  useEffect(() => {
    if (message) {
      displayError(message);
      setMessage("");
    }
  }, [message]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const statusCode = error?.response?.status;
        const errorResponseData = error?.response?.data;

        if ((statusCode === 401 || statusCode === 403) && !in401Error.current) {
          in401Error.current = true;

          if (!message) {
            setError(
              errorResponseData?.detail
                ? errorResponseData.detail
                : "Authentication failed."
            );
          }

          if (location.pathname !== ROUTE.LOGIN) {
            logout();
          }

          setTimeout(() => {
            in401Error.current = false; // Reset the flag after handling the error
          }, 1000);

          return Promise.reject(error);
        } else if (statusCode === 404) {
          navigate(ROUTE.NOT_FOUND);
        } else if (statusCode === 500 || statusCode === 503) {
          navigate(ROUTE.SERVER_ERROR);
        }

        return Promise.reject(error);
      }
    );

    // Cleanup the interceptor on component unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [location.pathname, logout, navigate]);

  return children;
};

export { axios, AxiosInterceptor };
