import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { User } from "../types/User";
import { env } from "../utils/env";
import { ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";

const fetchUser = (authHeader: string) => () =>
  axios.get<User>(`${env.verifyServiceUrl}user/details/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useUserDetails = (enabled: boolean, authHeader: string) => {
  const navigate = useNavigate();
  return useQuery(["userDetails"], fetchUser(authHeader), {
    enabled, retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
