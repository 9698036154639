import { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { Box } from "../../../components/Box";
import { Button } from "../../../components/Button";
import { Flex } from "../../../components/Flex";
import { H3 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import { Paragraph } from "../../../components/Paragraph";
import { TextArea } from "../../../components/TextArea";
import { FormQuestion } from "./FormQuestion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import deleteIcon from "../../../assets/settings/delete-red.svg";
import editIcon from "../../../assets/settings/edit.svg";
import { Image } from "../../../components/Image";
import { GrayCard } from "../../../components/Card";
import { useDeleteSection } from "../../../data/useDeleteSection";
import { queryClient } from "../../../queryClient";
import { showError } from "../../../utils/error-handling";
import { FormRow } from "../../../components/Form";
import { useFormSections } from "../../../data/useFormSections";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE } from "../../../routes";
import { useCreateSection } from "../../../data/useCreateSection";
import { notify } from "../../../utils/notify";
import { useUpdateSection } from "../../../data/useUpdateSection";
import { Modal } from "../../../components/Modal";
import { useQuestionsFiledTypeList } from "../../../data/useQuestionsFiledTypeList";
import { RiDeleteBin6Line, RiDraggable } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import * as icons from "../../../assets/questions";
import { getValidationSchema } from "../../../utils/validationSchema";

const SettingsNewFormQuestions = ({ initialFormData = null }) => {
  const params = useParams();
  const navigate = useNavigate();
  const maxSections = 5;
  const maxQuestions = 10;

  const [sections, setSections] = useState(
    initialFormData?.sections || [
      { title: "", description: "", questions: [], isEditing: true },
    ]
  );

  const [currentSectionIndex, setCurrentSectionIndex] = useState(null);
  const [editingSectionIndex, setEditingSectionIndex] = useState(null);
  const [sectionToDeleteIndex, setSectionToDeleteIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const { mutate: deleteSection } = useDeleteSection();
  const { mutate: createSection } = useCreateSection();
  const { mutate: updateSection } = useUpdateSection();
  const { data: fetchedSections, isLoading: isSectionsLoading } =
    useFormSections(parseInt(params.id));
  const { data: questionsFieldTypeList } = useQuestionsFiledTypeList();

  useEffect(() => {
    if (fetchedSections && fetchedSections.length > 0) {
      setSections(fetchedSections);
    }
  }, [fetchedSections]);

  useEffect(() => {
    if (
      !isSectionsLoading &&
      fetchedSections.length === 0 &&
      sections.length === 1 &&
      sections[0].isEditing === true
    ) {
      setEditingSectionIndex(0);
    }
  }, [sections, fetchedSections]);

  const handleToggleEditSection = (index) => {
    const updatedSections = sections.map((section, i) => ({
      ...section,
      isEditing: i === index ? !section.isEditing : section.isEditing,
    }));

    if (
      !updatedSections[index].id &&
      !updatedSections[index].isEditing &&
      updatedSections[index].questions.length === 0
    ) {
      updatedSections.splice(index, 1);
      setEditingSectionIndex(null);
    } else {
      setEditingSectionIndex(updatedSections[index].isEditing ? index : null);
    }

    setSections(updatedSections);
  };

  const handleSaveSection = async (index, props) => {
    const updatedSections = [...sections];
    const sectionToSave = { ...updatedSections[index] };
    delete sectionToSave.isEditing;

    if (
      sectionToSave._form &&
      typeof sectionToSave._form === "object" &&
      sectionToSave._form.id
    ) {
      sectionToSave._form = sectionToSave._form.id;
    }

    const sectionId = sectionToSave.id ? sectionToSave.id : null;
    delete sectionToSave.id;

    if (sectionToSave.questions) {
      sectionToSave.questions = sectionToSave.questions.map((question) => {
        if (question.id) {
          return {
            ...question,
            field_type: question?.field_type?.id || question?.field_type,
          };
        } else {
          return question;
        }
      });
    }

    if (sectionId) {
      updateSection(
        { params: sectionToSave, sectionId: sectionId },
        {
          onSuccess: (res) => {
            notify("Form details updated successfully!", {
              type: "success",
              position: "bottom-left",
            });

            updatedSections[index].isEditing = false;

            setEditingSectionIndex(null);

            setSections(updatedSections);
            props.setFieldValue("sections", updatedSections);
            queryClient.invalidateQueries(["formSections"]);
          },
          onError: (err) => {
            showError(err, "A problem occurred while creating the section.");
            updatedSections[index].isEditing = false;
          },
        }
      );
    } else {
      createSection(sectionToSave, {
        onSuccess: (res) => {
          notify("Form details updated successfully!", {
            type: "success",
            position: "bottom-left",
          });

          updatedSections[index].isEditing = false;

          setEditingSectionIndex(null);

          setSections(updatedSections);
          props.setFieldValue("sections", updatedSections);
          queryClient.invalidateQueries(["formSections"]);
        },
        onError: (err) => {
          showError(err, "A problem occurred while creating the section.");
          updatedSections[index].isEditing = false;
        },
      });
    }
  };

  const handleSectionChange = (index, field, value, props) => {
    const updatedSections = [...sections];
    updatedSections[index][field] = value;
    setSections(updatedSections);
    props.setFieldValue(`sections[${index}].${field}`, value);
  };

  const handleAddSection = (props) => {
    const newSection = {
      title: "",
      description: "",
      questions: [],
      isEditing: true,
    };

    const newSections = [...sections, newSection];
    setSections(newSections);

    // Set the editing index to the new section's index
    setEditingSectionIndex(newSections.length - 1);

    props.setFieldValue("sections", newSections);
  };

  const handleAddQuestion = (sectionIndex) => {
    setCurrentSectionIndex(sectionIndex);
    setCurrentQuestion(null);
    setModalOpen(true);
  };

  const handleSaveQuestion = (newQuestion, props) => {
    const updatedSections = [...sections];

    const currentSection = updatedSections[currentSectionIndex];

    if (currentQuestion) {
      // If a question is being edited, update the existing question
      const questionIndex = currentSection.questions.findIndex(
        (q) => q.id === currentQuestion.id
      );
      if (questionIndex !== -1) {
        currentSection.questions[questionIndex] = {
          ...newQuestion,
          order: currentSection.questions[questionIndex].order, // Retain existing order
        };
      }
    } else {
      // If it's a new question, add it to the section's questions array
      const orderedQuestion = {
        ...newQuestion,
        order: currentSection.questions.length, // Set order for a new question
      };
      currentSection.questions.push(orderedQuestion);
    }

    currentSection._form = params.id;
    currentSection.isEditing = true;
    updatedSections[currentSectionIndex] = currentSection;

    setSections(updatedSections);
    setCurrentQuestion(null);
    setModalOpen(false);

    props.setFieldValue("sections", updatedSections);
  };

  const handleRemoveSection = (sectionIndex, props) => {
    deleteSection(sections[sectionIndex].id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["formSections"]);
        const updatedSections = sections.filter((_, i) => i !== sectionIndex);
        setSections(updatedSections);
        props.setFieldValue("sections", updatedSections);
      },
      onError: (err) =>
        showError(err, "A problem occurred while deleting the section."),
    });
    setSectionToDeleteIndex(null);
  };

  const onDragEnd = (result, sectionIndex, props) => {
    if (!result.destination) return;

    const updatedSections = [...sections];
    const section = updatedSections[sectionIndex];

    // Remove the dragged question from the original position and insert at the new position
    const [reorderedQuestion] = section.questions.splice(
      result.source.index,
      1
    );
    section.questions.splice(result.destination.index, 0, reorderedQuestion);

    // Update the order property for each question based on the new array order
    section.questions = section.questions.map((question, index) => ({
      ...question,
      order: index, // Set order based on new position
    }));

    setSections(updatedSections);
    props.setFieldValue("sections", updatedSections);
  };

  const handleEditQuestion = (id, sectionIndex, questionIndex, props) => {
    setCurrentSectionIndex(sectionIndex);
    setCurrentQuestion(sections[sectionIndex].questions[questionIndex]);
    setModalOpen(true);
  };

  const handleDeleteQuestion = (sectionIndex, questionIndex, props) => {
    const updatedSections = [...sections];

    // Remove the question from the specified section
    updatedSections[sectionIndex].questions = updatedSections[
      sectionIndex
    ].questions.filter((_, qIndex) => qIndex !== questionIndex);

    setSections(updatedSections);

    props.setFieldValue("sections", updatedSections);
  };

  return (
    !isSectionsLoading && (
      <Formik
        initialValues={{
          sections: sections,
        }}
        enableReinitialize={true}
        validationSchema={getValidationSchema}
        onSubmit={() => {}}
      >
        {(props) => {
          const currentSectionValid =
            editingSectionIndex !== null &&
            props.values.sections[editingSectionIndex]?.title?.trim().length >
              2 &&
            props.values.sections[editingSectionIndex]?.description?.trim()
              .length >= 2 &&
            props.values.sections[editingSectionIndex]?.questions.length > 0;
          return (
            <>
              <H3 my="4" fontSize="3">
                Sections
              </H3>
              <Form>
                {sections.map((section, index) => (
                  <Box key={index} mb={4}>
                    <H3 color="gray.80" my="2" fontSize="2">
                      Section {index + 1}
                    </H3>
                    {section.isEditing ? (
                      <Box
                        borderBottom={"1px solid"}
                        borderBottomColor="gray.10"
                        pb={2}
                      >
                        <FormRow my={3}>
                          <Input
                            width={"100%"}
                            value={props.values.sections[index]?.title || ""}
                            placeholder="Section Title"
                            label="Section Title"
                            isRequired
                            onChange={(e) => {
                              handleSectionChange(
                                index,
                                "title",
                                e.target.value,
                                props
                              );
                            }}
                            hasError={Boolean(
                              props?.errors?.sections?.[index]?.title
                            )}
                            errorMessage={
                              props?.errors?.sections?.[index]?.title
                            }
                          />
                        </FormRow>

                        <FormRow my={3}>
                          <TextArea
                            width={"100%"}
                            value={
                              props.values.sections[index]?.description || ""
                            }
                            placeholder="Section Description"
                            label="Section Description"
                            rows={4}
                            onChange={(e) =>
                              handleSectionChange(
                                index,
                                "description",
                                e.target.value,
                                props
                              )
                            }
                            hasError={Boolean(
                              props?.errors?.sections?.[index]?.description
                            )}
                            errorMessage={
                              props?.errors?.sections?.[index]?.description
                            }
                          />
                        </FormRow>

                        <Box mt={3}>
                          <DragDropContext
                            onDragEnd={(result) =>
                              onDragEnd(result, index, props)
                            }
                          >
                            <Droppable droppableId={`droppable-${index}`}>
                              {(provided) => (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {section.questions.map((question, qIndex) => (
                                    <Box
                                      key={qIndex}
                                      borderBottom="1px solid"
                                      borderBottomColor="gray.30"
                                      my={2}
                                    >
                                      <Draggable
                                        draggableId={`draggable-${index}-${qIndex}`}
                                        index={qIndex}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <Flex
                                              justifyContent="space-between"
                                              alignItems="center"
                                              mb={2}
                                            >
                                              <Box>
                                                <Flex
                                                  gap="18"
                                                  alignItems={"center"}
                                                >
                                                  <Box>
                                                    <RiDraggable
                                                      size={18}
                                                      color="#677084"
                                                    />
                                                  </Box>

                                                  <Box>
                                                    <H3 mb="0" fontSize="16px">
                                                      Question {qIndex + 1}{" "}
                                                      {question.title} -{" "}
                                                      {question.field_type
                                                        .name ||
                                                        questionsFieldTypeList.data.find(
                                                          (type) =>
                                                            type.id ===
                                                            question.field_type
                                                        )?.name ||
                                                        ""}
                                                    </H3>
                                                    <Paragraph
                                                      my={0}
                                                      color={"gray.60"}
                                                    >
                                                      {question.description}
                                                    </Paragraph>
                                                  </Box>
                                                </Flex>
                                              </Box>
                                              <Flex gap="12">
                                                <Image
                                                  src={editIcon}
                                                  alt="edit icon"
                                                  height="24px"
                                                  width="24px"
                                                  onClick={() =>
                                                    handleEditQuestion(
                                                      question.id,
                                                      index,
                                                      qIndex,
                                                      props
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                />
                                                <Image
                                                  src={deleteIcon}
                                                  alt="delete icon"
                                                  height="24px"
                                                  width="24px"
                                                  onClick={() =>
                                                    handleDeleteQuestion(
                                                      index,
                                                      qIndex,
                                                      props
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                />
                                              </Flex>
                                            </Flex>
                                          </div>
                                        )}
                                      </Draggable>
                                    </Box>
                                  ))}
                                  {provided.placeholder}
                                </Box>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Box>

                        <Button
                          Icon={FaPlus}
                          iconSize="14px"
                          iconColor="blue.primary"
                          variant="blueTextWithBorder"
                          onClick={() => handleAddQuestion(index)}
                          isDisabled={section.questions.length >= maxQuestions}
                        >
                          Add Question
                        </Button>
                        <Flex gap="12" justifyContent={"end"}>
                          <Button
                            variant="primary"
                            onClick={() => handleSaveSection(index, props)}
                            isDisabled={!currentSectionValid}
                          >
                            Save
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => handleToggleEditSection(index)}
                          >
                            Cancel
                          </Button>
                        </Flex>
                      </Box>
                    ) : (
                      <GrayCard m={"0px"}>
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <H3 fontSize="2">{section.title}</H3>
                          <Flex gap="8">
                            <Button
                              Icon={MdModeEdit}
                              iconPosition="left"
                              iconSize="16px"
                              iconColor="gray.60"
                              variant="grayText"
                              onClick={() => handleToggleEditSection(index)}
                              isDisabled={
                                editingSectionIndex !== null &&
                                editingSectionIndex !== index
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger-text"
                              iconColor="red.primary"
                              Icon={RiDeleteBin6Line}
                              onClick={() => {
                                setSectionToDeleteIndex(index);
                              }}
                            >
                              Delete
                            </Button>
                          </Flex>
                        </Flex>
                        <Paragraph fontSize={1} color={"gray.60"}>
                          {section.description}
                        </Paragraph>
                        {section.questions.map((question, qIndex) => {
                          return (
                            <Flex key={qIndex} gap="12" alignItems={"center"}>
                              <Image
                                src={icons[question.field_type.slug_name]}
                                alt={`${question.field_type.name} icon`}
                                width="22px"
                                mr={1}
                              />
                              <Paragraph
                                fontSize={1}
                                color={"gray.60"}
                                my={1}
                                key={qIndex}
                              >
                                {`Question ${qIndex + 1} - ${
                                  question.title
                                } - ${question.field_type.name}`}
                              </Paragraph>
                            </Flex>
                          );
                        })}
                      </GrayCard>
                    )}
                  </Box>
                ))}
                <Button
                  variant="grayText"
                  Icon={FaPlus}
                  iconSize="14"
                  p={0}
                  fontWeight={500}
                  isDisabled={
                    (editingSectionIndex !== null &&
                      editingSectionIndex < props.values.sections.length) ||
                    (props.values.sections &&
                      props.values.sections.length >= maxSections)
                  }
                  onClick={() => handleAddSection(props)}
                >
                  Add Section
                </Button>

                <Flex justifyContent={"end"}>
                  <Button
                    isDisabled={editingSectionIndex !== null}
                    onClick={() => navigate(ROUTE.SETTINGS_FORMS)}
                  >
                    Finish
                  </Button>
                </Flex>
              </Form>

              {/* Question Modal */}
              <FormQuestion
                isOpen={isModalOpen}
                onRequestClose={() => setModalOpen(false)}
                onSave={(newQuestion) => handleSaveQuestion(newQuestion, props)}
                question={currentQuestion}
              />
              <Modal
                isOpen={sectionToDeleteIndex !== null}
                onClose={() => setSectionToDeleteIndex(null)}
                title="Delete Section?"
                content="Are you sure you want to delete this section? This action cannot be undone."
                onModalConfirmClick={() =>
                  handleRemoveSection(sectionToDeleteIndex, props)
                }
                secondaryButtonText="Cancel"
                primaryButtonText="Delete"
                primaryButtonVariant="danger"
                hasSecondaryButton
                width="450px"
              />
            </>
          );
        }}
      </Formik>
    )
  );
};

export default SettingsNewFormQuestions;
