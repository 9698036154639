import { Box } from "../../components/Box";
import {
  StaticBreadcrumb,
  Crumb,
  BreadcrumbDivider,
} from "../../components/Breadcrumbs";
import { Flex } from "../../components/Flex";
import { ROUTE } from "../../routes";
import { useNavigate } from "react-router-dom";
import { useDeleteMandate } from "../../data/useDeleteMandate";
import { queryClient } from "../../queryClient";
import { notify } from "../../utils/notify";
import { showError } from "../../utils/error-handling";
import { Modal } from "../../components/Modal";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IconButton } from "../../components/IconButton";

export const ApplicationTypeNewFrame = ({
  children,
  mandateId,
  step,
}: {
  children: any;
  step: "details" | "checks" | "forms" | "documents";
  mandateId?: number;
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();
  const { mutate: deleteMandate } = useDeleteMandate();

  const breadcrumbs = [
    {
      isActive: step === "details",
      title: "01 Details",
      key: 1,
    },
    {
      isActive: step === "checks",
      title: "02 Checks",
      key: 2,
    },
    {
      isActive: step === "forms",
      title: "03 Forms",
      key: 3,
    },
    {
      isActive: step === "documents",
      title: "04 Additional Documents",
      key: 4,
    },
  ];

  const handleDeleteCheck = () => {
    deleteMandate(
      {
        id: mandateId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["mandate"]);
          notify("Check deleted", {
            type: "success",
          });
          navigate(ROUTE.CHECKS);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while deleting the check.");
        },
      }
    );
  };

  return (
    <>
      <Box
        height="calc(100vh - 83px)"
        // overflowY="auto"
        bg="white"
        borderRadius={8}
      >
        <Box bg="white" borderRadius={8}>
          <Box>
            <Flex justifyContent={"end"}>
              <Box padding={2} marginLeft={2}>
                <StaticBreadcrumb>
                  {breadcrumbs.map(({ isActive, title, key }, index) => (
                    <Crumb
                      key={key}
                      isActive={isActive}
                      iconDivider={
                        index < breadcrumbs.length - 1 && <BreadcrumbDivider />
                      }
                    >
                      {title}
                    </Crumb>
                  ))}
                </StaticBreadcrumb>
              </Box>
              <IconButton
                Type={IoMdClose}
                size="23px"
                zIndex={9}
                onClick={() => navigate(ROUTE.SETTINGS_APPLICATION_TYPES)}
              />
            </Flex>
          </Box>
          <Box mt={"-80px"}>{children}</Box>
        </Box>
      </Box>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Do you want to delete this?"
        content="This is an irreversible action, all mandate and participant information will be permanently deleted. "
        onModalConfirmClick={handleDeleteCheck}
        secondaryButtonText="Cancel"
        primaryButtonText="Delete"
        primaryButtonVariant="danger"
        hasSecondaryButton
        width="450px"
      />
    </>
  );
};
