import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { User } from "../types/User";
import { env } from "../utils/env";
import { ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";

const fetchUser = (authHeader: string, id: number) => () =>
  axios.get<User>(`${env.verifyServiceUrl}user/user/${id}/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useOtherUserDetails = (id, enabled: boolean) => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();

  return useQuery(["userDetails", id], fetchUser(authHeader, id), {
    enabled, retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
