import { Form, Formik } from "formik";
import { FaPlus } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { H2, H3 } from "../Heading";
import { Loader } from "../Loader";
import { AddEditAdditionalDocument } from "../Modals/AddEditAdditionalDocument";
import { Paragraph } from "../Paragraph";
import { useState, useEffect } from "react";
import { useMandateTypeAdditionalDoc } from "../../data/useMandateTypeAdditionalDoc";
import { useUpdateMandateType } from "../../data/useUpdateMandateType";
import { useDirtyForm } from "../../pages/Settings/DirtyFormContext";
import { queryClient } from "../../queryClient";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { Toggle } from "../Toggle";

const RequestAdditionalDocument = ({ onAdditionalDocumentChange }) => {
  const params = useParams();
  const { setFormDirty, formRef } = useDirtyForm();
  const [selectedMandateTypeId, setSelectedMandateTypeId] = useState<
    number | null
  >(null);

  const { data } = useMandateTypeAdditionalDoc(selectedMandateTypeId);

  const { mutate: updateMandateType } = useUpdateMandateType();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [additionalDocumentArray, setAdditionalDocumentArray] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (params.id) {
      const id = parseInt(params.id);
      setSelectedMandateTypeId(id);
    }
  }, [params.id]);

  useEffect(() => {
    if (data) {
      const initiallyAddedDocuments = data
        .filter((doc) => doc.is_added === true)
        .map((doc) => doc.id);

      setAdditionalDocumentArray(initiallyAddedDocuments);
      onAdditionalDocumentChange(initiallyAddedDocuments);
    }
  }, [data, onAdditionalDocumentChange]);

  // Check if data is available before using it
  const addedDocumentIds = data
    ? data.filter((doc) => doc.is_added === true).map((doc) => doc.id)
    : [];

  const getInitialValues = () => {
    if (!data) return {};
    const initialValues = {};
    data.forEach((item) => {
      initialValues[`active_${item.id}`] = item.is_added || false;
    });
    return initialValues;
  };

  const handleActiveChange = (id: number, is_added: boolean) => {
    let updatedArray = [...additionalDocumentArray];

    if (is_added) {
      if (!updatedArray.includes(id)) {
        updatedArray.push(id);
      }
    } else {
      updatedArray = updatedArray.filter((docId) => docId !== id);
    }

    setAdditionalDocumentArray(updatedArray);
    onAdditionalDocumentChange(updatedArray);

    setFormDirty(true);
  };

  const addAdditionalDoc = () => {
    setIsEditModalOpen(true);
    setSelectedDocumentId(null);
  };

  const handleClose = () => {
    setIsEditModalOpen(false);
  };

  const handleDocumentSuccess = (id: number) => {
    let additionalDocumentArray: number[] = [...addedDocumentIds];
    additionalDocumentArray.push(id);

    updateMandateType(
      {
        params: {
          id: selectedMandateTypeId,
          additional_document: additionalDocumentArray,
        },
        id: selectedMandateTypeId,
      },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            queryClient.invalidateQueries(["mandateTypeAdditionalDoc"]);
            notify("Document added successfully!", { type: "success" });
          }
        },
        onError: (err) => {
          showError(err, "Error occured while updating");
        },
      }
    );
  };

  return (
    <>
      <Box pt={2} width={"100%"}>
        <Flex justifyContent="space-between" alignItems="center">
          <H2 fontSize={3} fontWeight={500}>
            Requests additional documents (optional)
          </H2>
          <Button
            Icon={FaPlus}
            iconSize="16px"
            onClick={addAdditionalDoc}
            variant="primary"
            type="button"
            fontWeight={"bold"}
          >
            Add additional document
          </Button>
        </Flex>
        {!data || data.length === 0 ? (
          <Flex justifyContent="center" alignItems="center" minHeight="25vh">
            <Loader />
          </Flex>
        ) : (
          <Formik
            initialValues={getInitialValues()}
            enableReinitialize
            validate={() => null}
            onSubmit={(values, actions) => {
              setFormDirty(false);
              actions.setSubmitting(false);
            }}
            innerRef={formRef}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <Box>
                  {data?.map((item) => (
                    <Flex
                      key={item.id}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      borderBottom={"1px solid"}
                      borderBottomColor="gray.10"
                      py={2}
                    >
                      <Box>
                        <H3 fontSize={2} mb={"0px"}>
                          {item.name}
                        </H3>
                        <Paragraph my={0} color={"gray.60"}>
                          {item.description}
                        </Paragraph>
                      </Box>
                      <Box>
                        <Toggle
                          isChecked={props.values[`active_${item.id}`] || false}
                          onChange={(e) => {
                            props.setFieldValue(
                              `active_${item.id}`,
                              e.target.checked
                            );
                            handleActiveChange(item.id, e.target.checked);
                            setFormDirty(true);
                          }}
                          mt={2}
                        />
                      </Box>
                    </Flex>
                  ))}
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Box>

      <AddEditAdditionalDocument
        isOpen={isEditModalOpen}
        onRequestClose={handleClose}
        documentId={selectedDocumentId}
        fromMandateType={true}
        onSuccess={handleDocumentSuccess}
      />
    </>
  );
};
export default RequestAdditionalDocument;
