import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { CompanyType } from "../types/CompanyType";
import { env } from "../utils/env";
import { companyTypesFallback } from "./fallbackPayloads/companyTypes";
import { asAxiosResponse } from "./fallbackPayloads/utilities";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetchCompanyTypes = (authHeader: string) => () =>
  axios.get<CompanyType[]>(`${env.verifyServiceUrl}common/company_type_list/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useCompanyTypes = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["companyTypes"], fetchCompanyTypes(authHeader), {
    initialData: () => asAxiosResponse(companyTypesFallback),
    retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
      if(err.request.status === 401) {
        navigate(ROUTE.LOGOUT);
      }
    }
  });
};
