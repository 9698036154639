import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { Country } from "../types/Country";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";


const fetch = () =>
  axios.get<Country[]>(`${env.verifyServiceUrl}common/country/`);

export const useCountries = () => {
  const navigate = useNavigate();
  return useQuery(["countries"], fetch, {retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
      if(err.request.status === 401) {
        navigate(ROUTE.LOGOUT);
      }
    }
  });
};
