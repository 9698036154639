import { useQuery } from "@tanstack/react-query";
import axios from "axios"; // Make sure to import axios correctly
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { Banner } from "../types/Banner";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

interface BannerResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Banner[];
}

const fetch = (authHeader: string, status: boolean) => () =>
  axios.get<BannerResponse>(`${env.verifyServiceUrl}common/banner/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useBanner = (status?: true) => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["banner"], fetch(authHeader, status), {
    enabled: true,
    retry: false,
    select: (response) => response.data.results, // Select the results array
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};


