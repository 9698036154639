import { useEffect, useState } from "react";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Paragraph } from "../Paragraph";
import { BiTrash } from "react-icons/bi";
import { useUpdateMandateType } from "../../data/useUpdateMandateType";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { queryClient } from "../../queryClient";
import { Checkbox } from "../Checkbox";
import { Modal } from "../Modal";
import { MandateTypeForm } from "../../types/Forms";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RiDraggable } from "react-icons/ri";

interface FormListDisplayProps {
  forms: MandateTypeForm[];
  mandateTypeId: number;
}

export const FormListDisplay = ({
  forms,
  mandateTypeId,
  onFormsUpdate,
}: FormListDisplayProps & {
  onFormsUpdate: (forms: MandateTypeForm[]) => void;
}) => {
  const [selectedForms, setSelectedForms] = useState<number[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [doubleClickOnDelete, setDoubleClickOnDelete] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState<number[]>([]);
  const [currentForms, setCurrentForms] = useState<MandateTypeForm[]>(forms);

  const { mutate: updateMandateType } = useUpdateMandateType();

  useEffect(() => {
    setCurrentForms(forms);
  }, [forms]);

  const handleSelectAll = () => {
    if (selectedForms.length === currentForms.length) {
      setSelectedForms([]);
    } else {
      setSelectedForms(currentForms.map((form) => form._form.id));
    }
  };

  const handleDeleteSelected = () => {
    setDoubleClickOnDelete(true);
    const updatedForms = currentForms.filter(
      (form) => !idsToDelete.includes(form._form.id)
    );
    updateMandateTypeWithForms(updatedForms);
  };

  const updateMandateTypeWithForms = (updatedForms: MandateTypeForm[]) => {
    const transformedForms = updatedForms.map((form, index) => ({
      _form: form._form.id,
      order: index, // Update order based on position
    }));
    const obj = { _form: transformedForms };

    updateMandateType(
      { params: obj, id: mandateTypeId },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            queryClient.invalidateQueries(["mandate", mandateTypeId]);
            notify("Form(s) updated successfully!", { type: "success" });
            setSelectedForms([]);
            setDoubleClickOnDelete(false);
            setIdsToDelete([]);
            setIsDeleteModalOpen(false);
            setCurrentForms(updatedForms);
            onFormsUpdate(updatedForms);
          }
        },
        onError: (err) => {
          showError(err, "Error occurred while updating");
          setDoubleClickOnDelete(false);
          setIsDeleteModalOpen(false);
        },
      }
    );
  };

  const openDeleteModal = (ids = selectedForms) => {
    setIdsToDelete(ids);
    setIsDeleteModalOpen(true);
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedForms = Array.from(currentForms);
    const [removed] = reorderedForms.splice(source.index, 1); // Remove the dragged item from its original position
    reorderedForms.splice(destination.index, 0, removed); // Insert it at the destination position

    setCurrentForms(reorderedForms);
    updateMandateTypeWithForms(reorderedForms);
  };

  if (!currentForms || currentForms.length === 0) {
    return (
      <Flex justifyContent="center" alignItems="center" minHeight="25vh">
        <Paragraph>No forms available</Paragraph>
      </Flex>
    );
  }

  return (
    <Box>
      <Flex my={2} justifyContent="space-between" alignItems="center">
        <Flex>
          <Checkbox
            isChecked={selectedForms.length === currentForms.length}
            onChange={handleSelectAll}
          />
          {selectedForms.length === currentForms.length && (
            <Paragraph fontWeight="bold" m={"0px"}>
              Selected {selectedForms.length}
            </Paragraph>
          )}
        </Flex>
        {selectedForms.length === currentForms.length && (
          <Flex gap="8">
            <Paragraph my={"0px"} color={"gray.60"} fontSize={1}>
              Remove all
            </Paragraph>
            <Icon
              Type={BiTrash}
              size={"18px"}
              onClick={() => openDeleteModal()}
              cursor="pointer"
            />
          </Flex>
        )}
      </Flex>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="formsList">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {currentForms.map((form, index) => (
                <Draggable
                  key={form._form.id}
                  draggableId={form._form.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom="1px solid #ccc"
                      padding="8px 0"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Flex gap="18" alignItems={"center"}>
                        <Box>
                          <RiDraggable size={18} color="#677084" />
                        </Box>

                        <Box>
                          <Paragraph fontWeight="bold">
                            {form?._form?.name}
                          </Paragraph>
                          <Paragraph fontSize={1} color={"gray.50"}>
                            {form._form.description}
                          </Paragraph>
                        </Box>
                      </Flex>
                      <Icon
                        Type={BiTrash}
                        size={"18px"}
                        onClick={() => openDeleteModal([form._form.id])}
                        cursor="pointer"
                      />
                    </Flex>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Do you want to delete this form(s)?"
        content="Selected form(s) will be deleted from application type."
        onModalConfirmClick={handleDeleteSelected}
        secondaryButtonText="Cancel"
        primaryButtonText="Delete"
        primaryButtonVariant="danger"
        hasSecondaryButton
        width="450px"
        doubleClick={doubleClickOnDelete}
      />
    </Box>
  );
};
