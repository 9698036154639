import { PagedResponse } from "../types/Common";
import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { DPOPeriod } from "../types/DPO";
import { ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";


const fetch = (authHeader: string) => () =>
  axios.get<PagedResponse<DPOPeriod[]>>(`${env.verifyServiceUrl}individual/data_retention_setting/`, {
    headers: {
      Authorization: authHeader,
    },
  });

export const useDPOPeriodDetails = () => {
const navigate = useNavigate();

  const { authHeader } = useAuth();
  return useQuery(["dpoPeriodDetails"], fetch(authHeader), {
    retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
  }
  });
};
