import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";
import { ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";


const fetch = (link: string) => () =>
  axios.get(`${env.verifyServiceUrl}user/forgot_password/?link=${link}`);

export const useVerifyPasswordResetLink = (link: string) => {
  const navigate = useNavigate();
  return useQuery(["passwordResetLink"], fetch(link), {
    retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
