import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { MandateType } from "../types/MandateType";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetchMandateTypes = (authHeader: string, mandateId?: number) => () =>
  axios.get<MandateType[]>(`${env.verifyServiceUrl}individual/mandate_type/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useMandateTypes = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["mandate_types"], fetchMandateTypes(authHeader), { retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
   });
};
