import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

export const useDeleteAdditionalDocument = () => {
    const { authHeader } = useAuth();
    return useMutation(({ id }: { id: number }) => {
        return axios.delete(
          `${env.verifyServiceUrl}individual/additional_document/${id}/`,
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
      });
  };
