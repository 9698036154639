import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

export const useChangePassword = () => {
  const navigate = useNavigate();
  return useMutation(({ password, link }: { password: string, link: string }) => {
    return axios.post(`${env.verifyServiceUrl}user/forgot_password/${link}/reset_password/`, {
      new_password: password,
      confirm_password: password,
      onError: (err: any) => {
        if(err.request.status === 500 || err.request.status === 503) {
          navigate(ROUTE.SERVER_ERROR);
        }
      }
    });
  });
};
