import * as yup from "yup";

const hasUpperCase = (value) => /[A-Z]/.test(value);
const hasLowercase = (value) => /[a-z]/.test(value);
const hasDigit = (value) => /[0-9]/.test(value);
const hasSymbol = (value) => /[!@#%&]/.test(value);

const passwordField = yup
  .string()
  .trim()
  .required("Password is required")
  .min(12, "Your password is too short.")
  .test(
    "hasUpperCase",
    "Must contain at least 1 uppercase character (A-Z)",
    hasUpperCase
  )
  .test("hasDigit", "Must contain at least 1 digit (0-9)", hasDigit)
  .test(
    "hasSpecial",
    "Must contain at least 1 special character (!@#%&)",
    hasSymbol
  )
  .test(
    "hasLowercase",
    "Must contain at least 1 lowercase character (a-z)",
    hasLowercase
  );

export const userSignUpValidation = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("The first name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  lastName: yup
    .string()
    .trim()
    .required("The last name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  password: passwordField,
  confirmPassword: yup
    .string()
    .trim()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Email address is required field."),
});

export const companyDetailsSignUpValidation = yup.object().shape({
  companyName: yup
    .string()
    .trim()
    .required("The Company name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(100, "Please enter no more than 100 characters."),
  companyWebsite: yup
    .string()
    .trim()
    .required("The Company website field is required.")
    .matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      "Invalid url."
    ),
  companyType: yup.string().required("The business type field is required."),
  supportEmailAddress: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Email address is required field."),
  supportPhoneNumber: yup
    .string()
    .min(10, "Minimum 10 numbers are required.")
    .max(15, "Please enter no more than 15 characters.")
    .matches(
      /^[0-9 +()-]*$/,
      "Please enter a valid number. The number can only contain digits, spaces, + ( ) -."
    ),
  companyReg: yup
    .string()
    .trim()
    .max(30, "Please enter no more than 30 characters.")
    .matches(
      /^[A-Za-z0-9 /()-]*$/,
      "Only letters, numbers, spaces, - / ( ) are allowed."
    ),
  companyIndustry: yup
    .string()
    .trim()
    .required("The Industry field is required."),
  hasAgreedTC: yup
    .boolean()
    .required("Please check the box to agree to the terms and conditions."),
});

export const companyAddressValidation = yup.object().shape({
  address_line_1: yup
    .string()
    .trim()
    .required("The address line 1 field is required.")
    .min(1, "Please enter at least 1 letter.")
    .max(50, "Please enter no more than 50 characters."),
  address_line_2: yup
    .string()
    .trim()
    .max(50, "Please enter no more than 50 characters."),
  city: yup
    .string()
    .trim()
    .required("The city field is required.")
    .max(50, "Please enter no more than 50 characters."),
  country: yup.string().trim().required("The country field is required."),
  postcode: yup
    .string()
    .trim()
    .required("The post/zip code field is required.")
    .min(1, "Please enter at least 1 letter.")
    .max(10, "Please enter no more than 10 characters.")
    .matches(
      /^[A-Za-z0-9 ]*$/,
      "Only letters, numbers and spaces are allowed."
    ),
});

export const BrandingValidation = yup.object().shape({
  text: yup
    .string()
    .trim()
    .max(200, "Please enter no more than 200 characters."),
  termsLink: yup
    .string()
    .url("Enter a valid URL.")
    .required("Terms and conditions link is required."),
  privacyLink: yup
    .string()
    .url("Enter a valid URL.")
    .required("Privacy policy link is required."),
});

export const billingInfoValidation = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("The first name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  lastName: yup
    .string()
    .trim()
    .required("The last name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Email address is required field."),
  telephone: yup
    .string()
    .trim()
    .required("Telephone number is required")
    .min(10, "Minimum 10 numbers are required.")
    .max(15, "Please enter no more than 15 characters.")
    .matches(
      /^[0-9 +()-]*$/,
      "Please enter a valid number. The number can only contain digits, spaces, + ( ) -."
    ),
  taxRegNumber: yup
    .string()
    .trim()
    .max(20, "Maximum 20 characters are allowed.")
    .nullable()
    .matches(
      /^[a-zA-Z0-9-/()]*$/,
      "Only numbers, letters, - / ( ) are allowed."
    ),
});

export const loginValidation = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: yup.string().trim().required("Password is required"),
});

export const addAdditionalDocumentValidations = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Document name is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(50, "Please enter no more than 50 characters."),
  // .matches(
  //   /^[\s\S]*$/,
  //   "Document name contains invalid characters."
  // ),
  description: yup
    .string()
    .trim()
    .required("Document description is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(200, "Please enter no more than 200 characters."),
  // .matches(
  //   /^[a-zA-Z '!._-]*$/,
  //   "Only letters, spaces, - ! . _ and ' are allowed."
  // ),
  document_capture_type: yup
    .array()
    .of(yup.number())
    .min(1, "Please select at least one option for document side.")
    .required("Please select at least one option for document side."),
});

export const addUserValidation = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("The first name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  lastName: yup
    .string()
    .trim()
    .required("The last name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Email address is required field."),
  jobTitle: yup
    .string()
    .trim()
    .required("The job title field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(50, "Please enter no more than 50 characters."),
  groups: yup.string().trim().required("Permissions field is required."),
});

export const Primary_Purpose_validation = yup.object().shape({
  primary_purpose: yup
    .string()
    .trim()
    .required("The primary purpose filed is required.")
    .max(250, "Please enter no more than 250 characters."),
});

export const DPO_validation = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("The name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Email address is required field."),
  phone_number: yup
    .string()
    .trim()
    .required("Contact number is required")
    .min(10, "Minimum 10 numbers are required.")
    .max(15, "Please enter no more than 15 characters.")
    .matches(
      /^[0-9 +()-]*$/,
      "Please enter a valid number. The number can only contain digits, spaces, + ( ) -."
    ),
});

export const settingsCompanyDetailsValidation = yup.object().shape({
  companyName: yup
    .string()
    .trim()
    .required("The Company name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(100, "Please enter no more than 100 characters."),
  companyDisplayname: yup
    .string()
    .nullable()
    .trim()
    .min(2, "Please enter at least 2 letters.")
    .max(100, "Please enter no more than 100 characters."),
  companyType: yup
    .string()
    .trim()
    .required("The company type field is required."),
  companyWebsite: yup
    .string()
    .trim()
    .required("The Company website field is required.")
    .matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      "Invalid URL."
    ),
  companyIndustry: yup
    .string()
    .trim()
    .required("The Industry field is required."),
  supportEmailAddress: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .nullable(),
  supportPhoneNumber: yup
    .string()
    .min(10, "Minimum 10 numbers are required.")
    .max(15, "Please enter no more than 15 characters.")
    .matches(
      /^[0-9 +()-]*$/,
      "Please enter a valid number. The number can only contain digits, spaces, + ( ) -."
    ),
  companyReg: yup
    .string()
    .trim()
    .max(30, "Please enter no more than 30 characters.")
    .nullable()
    .matches(
      /^[A-Za-z0-9 /()-]*$/,
      "Only letters, numbers, spaces, - / ( ) are allowed."
    ),
});

export const settingsApplicationTypesValidation = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("The name field is required.")
    .min(4, "Please enter at least 4 letters.")
    .max(50, "Please enter no more than 50 characters."),
  description: yup
    .string()
    .trim()
    .required("The description field is required.")
    .min(10, "Please enter at least 10 letters.")
    .max(250, "Please enter no more than 250 characters."),
});

export const passwordResetValidation = yup.object().shape({
  currentPassword: yup.string().required("Current password is required"),
  newPassword: passwordField,
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export const confirmPasswordResetValidation = yup.object().shape({
  newPassword: passwordField,
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export const createPasswordValidation = yup.object().shape({
  createPassword: passwordField,
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("createPassword"), null], "Passwords must match"),
});

export const emailVerificationValidation = yup.object().shape({
  code: yup
    .string()
    .trim()
    .required("Please enter your code.")
    .max(6, "The verification code is only six digits long.")
    .min(6, "The verification code is six digits long."),
});

export const addMandateValidation = yup.object().shape({
  applicationType: yup
    .string()
    .trim()
    .required("The application type field is required."),
  applicationReference: yup
    .string()
    .trim()
    .required("The application reference field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(30, "Please enter no more than 30 characters."),
  // consultant: yup.string().trim().required("The consultant field is required."),
  consultantName: yup
    .string()
    .trim()
    .when("consultant", {
      is: "create_consultant",
      then: yup
        .string()
        .trim()
        .required("The consultant name field is required.")
        .min(2, "Please enter at least 2 letters.")
        .max(50, "Please enter no more than 50 characters.")
        .matches(
          /^[a-zA-Z '!._-]*$/,
          "Only letters, spaces, - ! . _ and ' are allowed."
        ),
    }),
  consultantEmail: yup.string().when("consultant", {
    is: "create_consultant",
    then: yup
      .string()
      .trim()
      .email("Please enter a valid email address.")
      .required("Consultant email is required field."),
  }),
});

export const addIndividualValidation = yup.object().shape({
  gender: yup.string().required("The select gender field is required."),
  firstName: yup
    .string()
    .trim()
    .required("The first name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  lastName: yup
    .string()
    .trim()
    .required("The last name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Email address is required field."),
  middleName: yup
    .string()
    .trim()
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
});

export const addressFormValidationSchema = yup
  .object()
  .shape({
    building_number: yup
      .string()
      .max(50, "Please enter no more than 50 characters."),
    flat_appartment_subbuilding: yup
      .string()
      .max(75, "Please enter no more than 75 characters."),
    building_name: yup
      .string()
      .max(100, "Please enter no more than 100 characters."),
    road_street: yup
      .string()
      .max(100, "Please enter no more than 100 characters.")
      .min(1, "Please enter at least 1 character."),
    town_city: yup
      .string()
      .max(75, "Please enter no more than 75 characters.")
      .min(2, "Please enter at least 2 character."),
    district: yup
      .string()
      .max(75, "Please enter no more than 75 characters.")
      .min(2, "Please enter at least 2 characters."),
    state_province_name: yup
      .string()
      .max(75, "Please enter no more than 75 characters.")
      .min(2, "Please enter at least 2 character."),
    state_province_code: yup
      .string()
      .max(10, "Please enter no more than 10 characters.")
      .min(2, "Please enter at least 2 character."),
    post_zip_code: yup
      .string()
      .max(15, "Please enter no more than 15 characters.")
      .min(2, "Please enter at least 2 character."),
  })
  .test(
    "at-least-one",
    "At least one of building number, building name or flat number is required",
    function (value) {
      const { flat_appartment_subbuilding, building_number, building_name } =
        value;

      if (!flat_appartment_subbuilding && !building_number && !building_name) {
        const path = !building_number
          ? "building_number"
          : !flat_appartment_subbuilding
          ? "flat_appartment_subbuilding"
          : "road_street";

        return this.createError({
          path,
          message:
            "At least one of building number, building name or flat number is required",
        });
      }

      // Return true to indicate validation success
      return true;
    }
  );

export const addChecksIndividualValidation = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("The first name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  lastName: yup
    .string()
    .trim()
    .required("The last name field is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
  middleName: yup
    .string()
    .trim()
    .min(2, "Please enter at least 2 letters.")
    .max(25, "Please enter no more than 25 characters.")
    .matches(
      /^[a-zA-Z '!._-]*$/,
      "Only letters, spaces, - ! . _ and ' are allowed."
    ),
});

export const newMonitoringFormValidation = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .trim()
      .required("The first name field is required.")
      .min(2, "Please enter at least 2 letters.")
      .max(25, "Please enter no more than 25 characters.")
      .matches(
        /^[a-zA-Z '!._-]*$/,
        "Only letters, spaces, - ! . _ and ' are allowed."
      ),
    lastName: yup
      .string()
      .trim()
      .required("The last name field is required.")
      .min(2, "Please enter at least 2 letters.")
      .max(25, "Please enter no more than 25 characters.")
      .matches(
        /^[a-zA-Z '!._-]*$/,
        "Only letters, spaces, - ! . _ and ' are allowed."
      ),
    middleName: yup
      .string()
      .trim()
      .min(2, "Please enter at least 2 letters.")
      .max(25, "Please enter no more than 25 characters.")
      .matches(
        /^[a-zA-Z '!._-]*$/,
        "Only letters, spaces, - ! . _ and ' are allowed."
      ),
    gender: yup.string().required("This field is required"),
    pep_and_sanction_required: yup.string().required("This field is required"),
    adverse_media_required: yup.string().required("This field is required"),
  })
  .test(
    "at-least-one",
    "At least one of PEP & sanctions monitoring or Adverse media monitoring must be Yes.",
    function (value) {
      const { pep_and_sanction_required, adverse_media_required } = value;

      if (
        pep_and_sanction_required === "false" &&
        adverse_media_required === "false"
      ) {
        return this.createError({
          path: "pep_and_sanction_required",
          message:
            "At least one of PEP & sanctions monitoring or Adverse media monitoring must be Yes.",
        });
      }
      return true;
    }
  );

export const addEmailValidation = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("Please enter a valid email")
    .required("Required field"),
});

export const validateForm = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Form name is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(250, "Please enter no more than 250 characters."),
  // .matches(
  //   /^[a-zA-Z0-9 '!._-]*$/,
  //   "Only letters, numbers, spaces, - ! . _ and ' are allowed."
  // ),
  description: yup
    .string()
    .trim()
    .required("Form description is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(500, "Please enter no more than 500 characters."),
  // .matches(
  //   /^[a-zA-Z '!._-]*$/,
  //   "Only letters, spaces, - ! . _ and ' are allowed."
  // ),
});

export const getValidationSchema = yup.object().shape({
  sections: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .trim()
        .required("Section title is required.")
        .min(2, "Please enter at least 2 letters.")
        .max(250, "Please enter no more than 250 characters."),
      description: yup
        .string()
        .trim()
        .required("Section description is required.")
        .min(2, "Please enter more than 2 characters.")
        .max(500, "Please enter no more than 500 characters."),
      questions: yup
        .array()
        .min(1, "Each section must have at least one question."),
    })
  ),
});

export const validateQuestionForm = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required("Title is required.")
    .min(2, "Please enter at least 2 letters.")
    .max(250, "Please enter no more than 250 characters."),
  // .matches(
  //   /^[a-zA-Z '?!._-]*$/,
  //   "Only letters, spaces, ? - ! . _ and ' are allowed."
  // ),
  description: yup
    .string()
    .trim()
    .min(2, "Please enter at least 2 letters.")
    .max(500, "Please enter no more than 500 characters."),
  field_type: yup.string().required("Field type is required."),
});
