import { GrClose } from "react-icons/gr";
import { ProgressBar } from "../../components/ProgressBar";
import { Box } from "../../components/Box";
import {
  Breadcrumb,
  StaticBreadcrumb,
  Crumb,
  BreadcrumbDivider,
} from "../../components/Breadcrumbs";
import { Flex } from "../../components/Flex";
import { IconButton } from "../../components/IconButton";
import Topbar from "../../components/Topbar";
import { ROUTE } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { useDeleteMandate } from "../../data/useDeleteMandate";
import { queryClient } from "../../queryClient";
import { notify } from "../../utils/notify";
import { showError } from "../../utils/error-handling";
import { Modal } from "../../components/Modal";
import { useState } from "react";

export const NewApplicationFrame = ({
  children,
  mandateId,
  step,
}: {
  children: any;
  step: "mandate" | "individuals" | "invites";
  mandateId?: number;
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();
  const { mutate: deleteMandate } = useDeleteMandate();

  const progress = {
    mandate: 25,
    individuals: 50,
    invites: 75,
  }[step];

  const breadcrumbs = [
    {
      isActive: step === "mandate",
      title: "Application type",
      key: 1,
    },
    {
      isActive: step === "individuals",
      title: "Add individuals",
      key: 2,
    },
    {
      isActive: step === "invites",
      title: "Send invites",
      key: 3,
    },
  ];

  const handleDeleteApplication = () => {
    deleteMandate(
      {
        id: mandateId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["mandate"]);
          notify("Application deleted", {
            type: "success",
          });
          navigate(ROUTE.APPLICATIONS);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while deleting the application.");
          // displayError(
          //     collapseErrors(extractErrors(err)) ||
          //     "A problem occurred while deleting the application."
          // );
          // logError(err);
        },
      }
    );
  };

  return (
    <div>
      <Topbar
        header={
          <Flex justifyContent="space-between" width="100%">
            <>
              <Breadcrumb>
                <Breadcrumb.Section linkTo={ROUTE.APPLICATIONS}>
                  Applications
                </Breadcrumb.Section>
                <Breadcrumb.Section>
                  Create a new application
                </Breadcrumb.Section>
              </Breadcrumb>
            </>
            <Flex>
              {mandateId && (
                <Button
                  variant="danger"
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  Cancel Application
                </Button>
              )}
              <IconButton
                Type={GrClose}
                onClick={() => navigate(ROUTE.APPLICATIONS)}
                color="gray.40"
                ml={4}
                mr={3}
              />
            </Flex>
          </Flex>
        }
      ></Topbar>
      <Box height="calc(100vh - 83px)" overflowY="auto">
        <Box>
          <ProgressBar progress={progress} />
          <Box padding={2} marginLeft={2}>
            <StaticBreadcrumb>
              {breadcrumbs.map(({ isActive, title, key }, index) => (
                <Crumb
                  key={key}
                  isActive={isActive}
                  iconDivider={
                    index < breadcrumbs.length - 1 && <BreadcrumbDivider />
                  }
                >
                  {title}
                </Crumb>
              ))}
            </StaticBreadcrumb>
          </Box>
        </Box>
        <Flex position={"relative"} justifyContent="center" alignItems="center">
          <Box>{children}</Box>
        </Flex>
      </Box>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Do you want to delete this?"
        content="This is an irreversible action, all mandate and participant information will be permanently deleted. "
        onModalConfirmClick={handleDeleteApplication}
        secondaryButtonText="Cancel"
        primaryButtonText="Delete"
        primaryButtonVariant="danger"
        hasSecondaryButton
        width="450px"
      />
    </div>
  );
};
