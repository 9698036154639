import { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { useAdditionalDocumentById } from "../../data/useAdditionalDocumentById";
import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Input, InputProps } from "../Input";
import { Loader } from "../Loader";
import { Modal } from "../Modal";
import { Paragraph } from "../Paragraph";
import Select, { Option } from "../Select";
import { useDocumentCaptureTypeList } from "../../data/useDocumentCaptureTypeList";
import { useUpdateAdditionalDocument } from "../../data/useUpdateAdditionalDocument";
import { showError } from "../../utils/error-handling";
import { useCreateAdditionalDocument } from "../../data/useCreateAdditionalDocuments";
import { notify } from "../../utils/notify";
import { queryClient } from "../../queryClient";
import { addAdditionalDocumentValidations } from "../../utils/validationSchema";
import { BiTrash } from "react-icons/bi";
import { useDeleteAdditionalDocument } from "../../data/useDeleteAdditionalDocument";

interface FormData {
  name: string;
  description: string;
  document_capture_type: number[];
  is_global?: boolean;
}

interface AddEditAdditionalDocumentProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSuccess?: (id: number) => void; // Add this
  documentId?: number;
  fromMandateType?: boolean;
}

export const AddEditAdditionalDocument = ({
  isOpen,
  onRequestClose,
  onSuccess,
  documentId,
  fromMandateType,
}: AddEditAdditionalDocumentProps) => {
  const [doubleClick, setDoubleClick] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [doubleClickOnDelete, setDoubleClickOnDelete] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [initialSelectedOptions, setInitialSelectedOptions] = useState<
    Option[]
  >([]);

  const mode = documentId ? "edit" : "create";

  const {
    data: document,
    isLoading: isLoadingDocumentDetails,
    isFetching,
  } = useAdditionalDocumentById(documentId, mode === "edit" && isOpen);

  const { data: DocumentCaptureTypeList } = useDocumentCaptureTypeList();

  const { mutate: updateAdditionalDocument } = useUpdateAdditionalDocument();
  const { mutate: createAdditionalDocument } = useCreateAdditionalDocument();
  const { mutate: deleteAdditionalDocument } = useDeleteAdditionalDocument();
  const isLoadingDocument =
    mode === "edit" && (isLoadingDocumentDetails || isFetching);

  useEffect(() => {
    if (isOpen) {
      if (mode === "edit") {
        queryClient.invalidateQueries(["additionalDocument", documentId]);
        if (document && DocumentCaptureTypeList) {
          const preselectedOptions = document?.document_capture_type.map(
            (type: { id: number; name: string }) => ({
              label: type.name,
              value: type.id,
            })
          );
          setSelectedOptions(preselectedOptions || []);
          setInitialSelectedOptions(preselectedOptions || []); // Track initial selected options
        }
      } else if (mode === "create") {
        setSelectedOptions([]);
        setInitialSelectedOptions([]);
      }
    } else {
      // Reset state when the modal is closed
      setSelectedOptions([]);
      setInitialSelectedOptions([]);
    }
  }, [isOpen, document, DocumentCaptureTypeList, mode, documentId]);

  const handleSubmit = (form: FormData, { resetForm }: any) => {
    setDoubleClick(true);
    const onComplete = () => {
      queryClient.invalidateQueries(["addAdditionalDocuments"]);

      onRequestClose();
      resetForm();
      setDoubleClick(false);
    };

    const onError = (err: any) => {
      setDoubleClick(false);
      showError(err, "A problem occurred while updating the document details.");
    };

    const onSuccessHandler = (res, message: string) => {
      notify(message, { type: "success", position: "bottom-left" });

      if (typeof onSuccess === "function") {
        onSuccess(res.data.id); // Invoke the parent's onSuccess callback
      }

      onComplete();
    };

    if (mode === "edit") {
      updateAdditionalDocument(
        {
          params: form,
          id: documentId,
        },
        {
          onSuccess: (res) =>
            onSuccessHandler(res, "Document edited successfully!"),
          onError,
        }
      );
    } else {
      if (fromMandateType) {
        form.is_global = false;
      }
      createAdditionalDocument(form, {
        onSuccess: (res) =>
          onSuccessHandler(res, "Document added successfully!"),
        onError,
      });
    }
  };
  const handleDeleteSelected = () => {
    setDoubleClickOnDelete(true);
    onRequestClose();
    deleteAdditionalDocument(
      {
        id: documentId,
      },
      {
        onSuccess: () => {
          notify("Additional Document deleted", {
            type: "success",
          });
          setIsDeleteModalOpen(false);
          queryClient.invalidateQueries(["addAdditionalDocuments"]);
        },
        onError: (err: any) => {
          setDoubleClickOnDelete(false);
          showError(err, "A problem occurred while deleting the additional document.");
        },
      }
    );
  };

  const handleSelectChange = (
    selectedOption: Option | Option[] | null,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const selected = Array.isArray(selectedOption)
      ? selectedOption
      : selectedOption
      ? [selectedOption]
      : [];
    setSelectedOptions(selected);

    // Only set the specific field for document_capture_type without touching other form fields
    setFieldValue(
      "document_capture_type",
      selected.map((option) => option.value)
    );
  };

  const options = useMemo(
    () =>
      DocumentCaptureTypeList?.data.map(({ name, id }) => ({
        label: name,
        value: id,
      })) as Option[],
    [DocumentCaptureTypeList]
  );

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  const isFormValid = (props: any) => {
    const formFieldsChanged = props.dirty;
    const optionsChanged =
      JSON.stringify(selectedOptions) !==
      JSON.stringify(initialSelectedOptions);

    return (
      !doubleClick && props.isValid && (formFieldsChanged || optionsChanged)
    );
  };

  return (
    <Modal width="700px" isOpen={isOpen} onClose={onRequestClose}>
      {isLoadingDocument ? (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      ) : (
        <Formik
          initialValues={{
            name: document?.name || "",
            description: document?.description || "",
            document_capture_type:
              selectedOptions.map((option) => Number(option.value)) || [],
          }}
          // enableReinitialize={true}
          validationSchema={addAdditionalDocumentValidations}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Box overflowY="auto">
                <Paragraph fontWeight="bold" fontSize={3}>
                  {documentId
                    ? "Edit additional document"
                    : "Add additional document"}
                </Paragraph>

                <Box mb={2}>
                  <Input
                    onChange={props.handleChange}
                    value={props.values.name}
                    placeholder="Enter document name"
                    label="Document name"
                    name="name"
                    {...inputProps}
                    isRequired
                    hasError={Boolean(props.errors.name)}
                    errorMessage={props.errors.name as string}
                  />
                </Box>
                <Box mb={2}>
                  <Select
                    isMulti
                    isInModal
                    name="document_capture_type"
                    options={options}
                    value={selectedOptions}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, props.setFieldValue)
                    }
                    placeholder="Select document type"
                    label="Document side"
                    isRequired
                    hasError={Boolean(props.errors.document_capture_type)}
                    errorMessage={props.errors.document_capture_type as string}
                  />
                </Box>

                <Box mb={2}>
                  <Input
                    onChange={props.handleChange}
                    value={props.values.description}
                    placeholder="Document description"
                    label="Enter description"
                    name="description"
                    {...inputProps}
                    isRequired
                    hasError={Boolean(props.errors.description)}
                    errorMessage={props.errors.description as string}
                  />
                </Box>
              </Box> 

              <Flex justifyContent="space-between">
              <Flex>
              {documentId && (
                  <Button
                    type="button"
                    Icon= {BiTrash}
                    iconColor="red"
                    width="100%"
                    mt="4"
                    mr={2}
                    mb="0"
                    variant="danger-text"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    Delete Document
                  </Button>
              )}
                </Flex>
                <Flex>
                  <Button
                    type="button"
                    width="100%"
                    mt="4"
                    mr={2}
                    mb="0"
                    variant="secondary"
                    onClick={onRequestClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    width="100%"
                    mt="4"
                    mb="0"
                    isDisabled={!isFormValid(props)}
                  >
                    {documentId ? "Edit" : "Create"}
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Are you sure you want to delete this document?"
        content="This is additional document will be removed from all existing applications types."
        onModalConfirmClick={handleDeleteSelected}
        secondaryButtonText="Cancel"
        primaryButtonText="Delete"
        primaryButtonVariant="danger"
        hasSecondaryButton
        width="450px"
        doubleClick={doubleClickOnDelete}
      />
    </Modal>
  );
};
