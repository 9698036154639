import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { MandateTypeAdditionalDoc } from "../types/AdditionalDocumentsType";
import { ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";

const fetch = (authHeader: string, id: number) => () =>
  axios.get<MandateTypeAdditionalDoc[]>(
    `${env.verifyServiceUrl}individual/mandate_type/${id}/additional_document/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useMandateTypeAdditionalDoc = (id: number | null) => {
  const { authHeader, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  return useQuery(
    ["mandateTypeAdditionalDoc", id], // include id as a dependency for caching
    fetch(authHeader, id as number), // cast id as number, but only when query is enabled
    {
      enabled: isLoggedIn && id !== null, // only enable query when logged in and id is not null
      retry: false,
      select: (response) => response.data, // Select the results array
      onError: (err: any) => {
        if(err.request.status === 500 || err.request.status === 503) {
          navigate(ROUTE.SERVER_ERROR);
        }
      }
    }
  );
};
