import { Formik, Form } from "formik";
import { Navigate } from "react-router-dom";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { H1 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { UserVerificationStatus, useAuth } from "../../context/AuthContext";
import { ROUTE } from "../../routes";
import { Input } from "../../components/Input";
import { billingInfoValidation } from "../../utils/validationSchema";
import { useState } from "react";
import { Checkbox } from "../../components/Checkbox";
import { useClientDetails } from "../../data/useClientDetails";
import { useUserDetails } from "../../data/useUserDetails";
import { useAddressForm } from "../../utils/forms/useAddressForm";

export const BillingInformationStep = ({ onSubmit }: any) => {
  const { isLoggedIn, authHeader, userVerificationStatus } = useAuth();

  const { data: user } = useUserDetails(isLoggedIn, authHeader);

  const { getInitialValues } = useAddressForm();

  const { data: client } = useClientDetails(user?.data.tenant);

  const [isChecked, setIsChecked] = useState(true);

  /** User is not ready for this step of the flow. */
  if (!isLoggedIn) {
    return <Navigate to={ROUTE.SIGN_UP} replace />;
  }
  // if (isChecked) {
  //   return <Navigate to={ROUTE.ADD_PRIMARY_PURPOSE} replace />;
  // }

  if (
    userVerificationStatus === UserVerificationStatus.PrimaryPurposeRequired
  ) {
    return <Navigate to={ROUTE.ADD_PRIMARY_PURPOSE} replace />;
  }

  if (userVerificationStatus === UserVerificationStatus.DPORequired) {
    return <Navigate to={ROUTE.ADD_DPO} replace />;
  }

  const checkHandler = async (event, fields) => {
    setIsChecked(!isChecked);
    if (isChecked) {
      fields.setFieldValue("address_line_1", client.data.address_line_1);
      fields.setFieldValue("address_line_2", client.data.address_line_2);
      fields.setFieldValue("city", client.data.city);
      fields.setFieldValue("country", client.data.country);
      fields.setFieldValue("postcode", client.data.postcode);

      await fields.setFieldTouched("address_line_1", true, false);
      await fields.setFieldTouched("address_line_2", true, false);
      await fields.setFieldTouched("city", true, false);
      await fields.setFieldTouched("country", true, false);
      await fields.setFieldTouched("postcode", true, false);

      // fields.validateForm();
    } else {
      // fields.resetForm();
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          telephone: "",
          taxRegNumber: "",
          same_as_company_address: false,
          ...getInitialValues(),
        }}
        validationSchema={billingInfoValidation}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <H1 color="darkBlue">Billing Information</H1>
            <Paragraph color="darkBlue">
              Please provide the details of an accounts team member for
              invoicing purposes
            </Paragraph>
            <Input
              onChange={props.handleChange}
              value={props.values.firstName}
              hasError={Boolean(props.errors.firstName)}
              errorMessage={props.errors.firstName}
              placeholder="Accounts person first name"
              mt="4"
              label="First name"
              name="firstName"
              isRequired
            />
            <Input
              onChange={props.handleChange}
              value={props.values.lastName}
              hasError={Boolean(props.errors.lastName)}
              errorMessage={props.errors.lastName}
              placeholder="Accounts person last name"
              mt="2"
              label="Last name"
              name="lastName"
              isRequired
            />
            <Input
              onChange={props.handleChange}
              value={props.values.email}
              hasError={Boolean(props.errors.email)}
              errorMessage={props.errors.email}
              placeholder="Accounts email address"
              mt="2"
              label="Email Address"
              name="email"
              isRequired
            />
            <Input
              onChange={props.handleChange}
              value={props.values.telephone}
              hasError={Boolean(props.errors.telephone)}
              errorMessage={props.errors.telephone}
              placeholder="Accounts person telephone number"
              mt="2"
              label="Telephone number"
              name="telephone"
              isRequired
            />
            <br />
            <Paragraph color="darkBlue">Add billing address details</Paragraph>
            <Checkbox
              onChange={(event) => {
                props.setFieldValue(
                  "same_as_company_address",
                  event.target.checked
                );
                checkHandler(event, props);
              }}
              label="Same as business address"
              isChecked={props.values.same_as_company_address}
              mt="2"
              name="same_as_company_address"
            />

            {/* <Input
              onChange={props.handleChange}
              value={props.values.taxRegNumber}
              hasError={Boolean(props.errors.taxRegNumber)}
              errorMessage={props.errors.taxRegNumber}
              placeholder="Enter tax registration number"
              mt="2"
              label="Tax registration number (VAT/ISE)"
              name="taxRegNumber"
            /> */}

            <Box>
              <Button
                size="large"
                width="100%"
                mt="2"
                mb="0"
                type="submit"
                isDisabled={!props.isValid || !props.dirty}
              >
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Box />
    </>
  );
};
