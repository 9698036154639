import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { AdditionalDocumentsList } from "../types/AdditionalDocumentsType";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetch = (authHeader: string) => () =>
  axios.get<AdditionalDocumentsList[]>(
    `${env.verifyServiceUrl}individual/additional_document/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useAllAdditionalDocuments = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["addAdditionalDocuments"], fetch(authHeader), {
    retry: false,
    select: (response) => response.data,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
