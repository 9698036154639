import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { PagedResponse } from "../types/Common";
import { Consultant } from "../types/Consultant";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

//TODO Hardcoded pagination deemed that we wouldn't need as it's unlikely that there will be more than 25 individuals against a mandate
const fetchConsultants = (authHeader: string) => () =>
  axios.get<PagedResponse<Consultant[]>>(
    `${env.verifyServiceUrl}individual/mandate_consultant/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useConsultants = (mandateId?: number) => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["consultants"], fetchConsultants(authHeader), {retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
