import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { Industry } from "../types/Industry";
import { env } from "../utils/env";
import { industriesFallback } from "./fallbackPayloads/industries";
import { asAxiosResponse } from "./fallbackPayloads/utilities";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetchIndustries = (authHeader: string) => () =>
  axios.get<Industry[]>(`${env.verifyServiceUrl}common/industry_list/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useIndustries = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["industries"], fetchIndustries(authHeader), {
    initialData: () => asAxiosResponse(industriesFallback),
    retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
      if(err.request.status === 401) {
        navigate(ROUTE.LOGOUT);
      }
    }
  });
};
