import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { MandateStatus } from "../types/MandateStatus";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetch = (authHeader: string) => () =>
  axios.get<MandateStatus[]>(
    `${env.verifyServiceUrl}common/mandate_status_list/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useMandateStatuses = () => {
  const { authHeader, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  return useQuery(["mandateStatuses"], fetch(authHeader), { enabled: isLoggedIn, retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
   } );
};
