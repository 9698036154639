import { useState, useMemo } from "react";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Loader } from "../Loader";
import { H3 } from "../Heading";
import { Paragraph } from "../Paragraph";
import { Button } from "../Button";
import { notify } from "../../utils/notify";
import { Modal } from "../Modal";
import { useForms } from "../../data/useForms";
import { Toggle } from "../Toggle";
import { Form, Formik } from "formik";
import { useUpdateMandateType } from "../../data/useUpdateMandateType";
import { showError } from "../../utils/error-handling";
import { MandateTypeForm } from "../../types/Forms";

interface FormListProps {
  isOpen: boolean;
  onRequestClose: () => void;
  mandateTypeId: number;
  includedForms: MandateTypeForm[];
}

export const FormList = ({
  isOpen,
  onRequestClose,
  mandateTypeId,
  includedForms,
}: FormListProps) => {
  const { data, isLoading: isLoadingForms } = useForms();
  const { mutate: updateMandateType } = useUpdateMandateType();

  const [activeForms, setActiveForms] = useState([]);

  // Filter out included forms from data
  const filteredForms = useMemo(() => {
    if (!data) return [];
    return data.filter(
      (form) =>
        !includedForms.some((includedForm) => includedForm._form.id === form.id)
    );
  }, [data, includedForms]);

  const handleActiveChange = (id, isAdded) => {
    setActiveForms((prev) => {
      if (isAdded) {
        return prev.includes(id) ? prev : [...prev, id];
      } else {
        return prev.filter((formId) => formId !== id);
      }
    });
  };

  const getInitialValues = () => {
    if (!filteredForms) return {};
    const initialValues = {};
    filteredForms.forEach((item) => {
      initialValues[`active_${item.id}`] = false;
    });
    return initialValues;
  };

  const handleSubmit = (formsArray) => {
    const combinedForms = [
      ...includedForms.map((item, index) => ({
        _form: item._form.id,
        order: index,
      })),
      ...formsArray.map((item, index) => ({
        _form: item,
        order: includedForms.length + index,
      })),
    ];
    const uniqueFormsMap = new Map();
    combinedForms.forEach((form) => {
      if (!uniqueFormsMap.has(form._form)) {
        uniqueFormsMap.set(form._form, form);
      }
    });

    const uniqueCombinedForms = Array.from(uniqueFormsMap.values());

    const obj = {
      is_disabled: false,
      _form: uniqueCombinedForms,
    };

    updateMandateType(
      { params: obj, id: mandateTypeId },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            notify("Forms in application type updated successfully!", {
              type: "success",
            });
          }
          setActiveForms([]);
          onRequestClose();
        },
        onError: (err) => {
          showError(err, "Error occurred while updating");
        },
      }
    );
  };

  return (
    <Modal
      height={"70%"}
      width="700px"
      isOpen={isOpen}
      onClose={onRequestClose}
    >
      <H3>Include Forms</H3>
      <Paragraph color={"gray.60"} fontSize={1}>
        You can add forms to this application. These forms will need to be
        completed by your customer as part of the verification process. Each
        form will be in the list of actions within the mobile app in the order
        below.
      </Paragraph>
      {isLoadingForms ? (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      ) : (
        <Formik
          initialValues={getInitialValues()}
          enableReinitialize
          validate={() => null}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            handleSubmit(activeForms);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Box>
                {filteredForms.map((item) => (
                  <Flex
                    key={item.id}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    borderBottom={"1px solid"}
                    borderBottomColor="gray.10"
                    py={2}
                  >
                    <Box>
                      <H3 fontSize={2} mb={"0px"}>
                        {item.name}
                      </H3>
                      <Paragraph my={0} color={"gray.60"} fontSize={1}>
                        {item.description}
                      </Paragraph>
                    </Box>
                    <Box>
                      <Toggle
                        isChecked={props.values[`active_${item.id}`] || false}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          props.setFieldValue(`active_${item.id}`, isChecked);
                          handleActiveChange(item.id, isChecked);
                        }}
                        mt={2}
                      />
                    </Box>
                  </Flex>
                ))}
              </Box>

              <Flex py={4} justifyContent="end">
                <Button type="submit" size="regular" isDisabled={!props.dirty}>
                  Add
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
