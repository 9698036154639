import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { HelpSupportOption } from "../types/HelpSupportOption";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetchOptions = (authHeader: string) => () =>
  axios.get<HelpSupportOption[]>(
    `${env.verifyServiceUrl}common/support_option_list/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useHelpSupportOptions = () => {
  const { authHeader, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  return useQuery(["helpOptions"], fetchOptions(authHeader), {
    enabled: isLoggedIn,
    retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
