import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { Checkpoint } from "../types/Check";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

//TODO Hardcoded pagination deemed that we wouldn't need as it's unlikely that there will be more than 25 individuals against a mandate
const fetchChecks = (authHeader: string, mandateId?: number) => () =>
  axios.get<Checkpoint[]>(
    `${env.verifyServiceUrl}individual/mandate_check/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );
export const useChecks = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["checks_list"], fetchChecks(authHeader), { retry: false ,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
