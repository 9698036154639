import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";

export const useLogin = () => {
  //change Login url for 2FA if required
  return useMutation(({ email, password }: any) => {
    return axios.post(`${env.verifyServiceUrl}user/token/obtain/`, {
      email,
      password,
    });
  });
};
