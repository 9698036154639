import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { Status } from "../types/status";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";


const fetchMonitoringStatus = (authHeader: string) => () =>
  axios.get<Status[]>(`${env.verifyServiceUrl}common/monitoring_status_list/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useMonitoringStatus = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  const query = useQuery(["Status"], fetchMonitoringStatus(authHeader), {retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });

  return query;
};
