import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

interface Title {
    name: string;    
};

  
const fetchTitles = () => () =>
  axios.get<Title[]>(
    `${env.verifyServiceUrl}common/titles/`,
  );
export const useCommonTitles = () => {
  const navigate = useNavigate();
  return useQuery(["common_titles"], fetchTitles(), {retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    }
  });
};
