import { useState } from "react";
import styled from "styled-components";
import { Link } from "../Link";
import { Box } from "../Box";

const TabsContainer = styled(Box)`
  ${({ theme: { fontFamily } }) => `
    font-family: ${fontFamily};
    font-weight: bold;
    width: 100%;
  `}
`;

const Nav = styled.div`
  ${({ theme: { colors, space } }) => `
  display: flex;
  border-bottom: 1px solid ${colors.gray[10]};
  align-items: center;
  justify-content: flex-start;
  margin-left: ${space[3]};
   `}
`;

const NavLink = styled(Link)<{ isActive: boolean }>`
  ${({ theme: { colors, space }, isActive }) => `
    padding: 1rem 1.5rem;
    cursor: pointer;
    list-style: none;
    color: ${isActive ? colors.gray[80] : colors.gray[50]};
    margin-right: ${space[1]};
    border-bottom: 4px solid ${isActive ? colors.blue.primary : "transparent"};
    transition: color 0.3s ease, border-bottom 0.3s ease;
    

    &:hover {
      color: ${colors.gray[70]};
      border-bottom-color: ${!isActive ? colors.gray[20] : colors.blue.primary};
    }

    &:focus {
      outline: none;
    }
  `}
`;

const TabContent = styled(Box)<{ isActive: boolean }>`
  ${({ isActive }) => `
    opacity: ${isActive ? 1 : 0};
    transition: opacity 0.3s ease, transform 0.3s ease;
    display: ${isActive ? "block" : "none"};
    width: 100%;
  `}
`;

const CustomTabs = ({
  tabs,
  activeTab: controlledActiveTab,
  onTabChange = (_index: number) => {}, // Accepts a number as a parameter
  activeTabIndex = 0,
}: {
  tabs: { label: React.ReactNode; content: React.ReactNode }[];
  activeTab?: number;
  onTabChange?: (index: number) => void;
  activeTabIndex?: number;
}) => {
  // Internal state for uncontrolled behavior
  const [internalActiveTab, setInternalActiveTab] = useState(activeTabIndex);

  // Determine whether to use controlled or uncontrolled behavior
  const isControlled = controlledActiveTab !== undefined;
  const activeTab = isControlled ? controlledActiveTab : internalActiveTab;

  const handleTabClick = (index) => {
    if (!isControlled) {
      setInternalActiveTab(index);
    }

    onTabChange(index);
  };

  return (
    <TabsContainer>
      <Nav>
        {tabs.map((tab, index) => (
          <NavLink
            key={index}
            isActive={activeTab === index}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </NavLink>
        ))}
      </Nav>
      {tabs.map((tab, index) => (
        <TabContent my={3} key={index} isActive={activeTab === index}>
          {tab.content}
        </TabContent>
      ))}
    </TabsContainer>
  );
};

export default CustomTabs;
