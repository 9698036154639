import { Box } from "../Box";
import { DateRangePicker } from "../DateRangePicker/index";
import { Paragraph } from "../Paragraph";
import { Slider } from "../Slider";
import { Form, Formik } from "formik";
import Select from "../Select";
import { Button } from "../Button";
import { useMandateCheckList } from "../../data/useMandateCheckList";
import { capitalise } from "../../utils/string";

type FiltersProps = {
  isOpen?: boolean;
  onRequestClose: () => void;
  filters: any;
  onSubmit: (values: any) => void;
};

const initialState = {
  dateStarted: [null, null],
  checkType: null,
};

export const ChecksFilters = (props: FiltersProps) => {
  const { isOpen, onRequestClose, onSubmit, filters } = props;
  const { data: mandateCheckList } = useMandateCheckList(true);

  const options = mandateCheckList?.data?.map(({ id, name }) => ({
    label: capitalise(name.replace(/_/g, " ")),
    value: name,
  }));

  return (
    <Slider isOpen={isOpen} onRequestClose={onRequestClose}>
      <Box p={3} overflowY="auto" height={"calc(100vh - 138px)"}>
        <Formik
          initialValues={filters}
          onSubmit={(values, helpers) => {
            onSubmit(values);
            helpers.resetForm({ values });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Paragraph as="h2" fontSize={3} mt="0px" display="flex">
                Filters
                <Button
                  type="reset"
                  px={2}
                  variant="text"
                  size="small"
                  position="absolute"
                  top="26px"
                  right="75px"
                  onClick={() => {
                    props.resetForm({ values: initialState });
                    props.submitForm();
                  }}
                >
                  Clear All
                </Button>
              </Paragraph>

              <DateRangePicker
                value={props.values.dateStarted}
                onChange={(option: any) =>
                  props.setFieldValue("dateStarted", option)
                }
                mt={3}
                label="Date Started"
                name="dateStarted"
              />

              <Select
                onChange={(option: any) =>
                  props.setFieldValue("checkType", option.value)
                }
                value={
                  options.find(
                    ({ value }) => value === props.values.checkType
                  ) || ""
                }
                options={options}
                mt={3}
                label="Check Type"
                name="checkType"
              />

              {props.dirty && (
                <Button type="submit" mt="2" size="large" width="100%">
                  Apply
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Slider>
  );
};
