import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { FormList } from "../../components/FormList";
import { FormListDisplay } from "../../components/FormListDisplay";
import { BiPlus } from "react-icons/bi";
import { Link } from "../../components/Link";
import theme from "../../theme";
import { useEffect, useState } from "react";
import { MandateTypeForm } from "../../types/Forms";
import { useMandateType } from "../../data/useMandateType";

type SettingsApplicationTypeFormsProps = {
  mandateTypeId: number;
  initialFormsData: MandateTypeForm[]; // Use for initial fallback data
};

const SettingsApplicationTypeForms: React.FC<
  SettingsApplicationTypeFormsProps
> = ({ mandateTypeId, initialFormsData }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { data: mandateData, refetch } = useMandateType(mandateTypeId);
  const [formsData, setFormsData] = useState<MandateTypeForm[]>(initialFormsData);
  // const formsData = mandateData?.data._form || initialFormsData || [];


  useEffect(() => {
    if (mandateData?.data._form) {
      setFormsData(mandateData.data._form);
    }
  }, [mandateData]);


  const handleIncludeForms = () => {
    setIsEditModalOpen(true);
  };

  const handleClose = () => {
    refetch();
    setIsEditModalOpen(false);
  };

  const handleFormsUpdate = (updatedForms: MandateTypeForm[]) => {
    setFormsData(updatedForms);
  };

  return (
    <>
      <Box pt={2}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <H2 fontSize={3} fontWeight={500}>
              Forms{" "}
              <span style={{ fontSize: "12px", color: "#677084" }}>
                (optional)
              </span>
            </H2>
          </Flex>
          <Button
            lineHeight={"10px"}
            Icon={BiPlus}
            iconSize="20px"
            iconColor="blue.primary"
            onClick={handleIncludeForms}
            variant="blueTextWithBorder"
            type="button"
            fontWeight={"bold"}
          >
            Include Forms
          </Button>
        </Flex>
        <Paragraph color={"gray.60"} fontSize={1} width={"70%"}>
          You can add forms to this application. These forms will need to be
          completed by your customer as part of the verification process. Each
          form will be in the list of actions within the mobile app in the order
          below.
        </Paragraph>
        <hr color={theme.colors.gray[10]}></hr>
        {formsData.length === 0 ? (
          <Flex>
            <Paragraph color={"gray.60"}>
              No forms included yet.{" "}
              <Link rel="forms" onClick={handleIncludeForms}>
                Include forms now
              </Link>{" "}
            </Paragraph>
          </Flex>
        ) : (
          <FormListDisplay forms={formsData} mandateTypeId={mandateTypeId} onFormsUpdate={handleFormsUpdate}/>
        )}
      </Box>

      <FormList
        isOpen={isEditModalOpen}
        onRequestClose={handleClose}
        mandateTypeId={mandateTypeId}
        includedForms={formsData}
      />
    </>
  );
};

export default SettingsApplicationTypeForms;
