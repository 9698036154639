import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

export const useVerifyCode = () => {
  const navigate = useNavigate();
  return useMutation(({ email, code }: { email: string; code: string }) => {
    return axios.post(`${env.verifyServiceUrl}user/confirm_verify_code/`, {
      email,
      verify_code: code,
      onError: (err: any) => {
        if(err.request.status === 500 || err.request.status === 503) {
          navigate(ROUTE.SERVER_ERROR);
        }
      }
    });
  }
);
};
